import React, { Component } from "react";
import Landing from "./components/landing/landing";

class App extends Component {
  render() {
    return <Landing />;
  }
}

export default App;
