import axios from "axios";
import React, { Component } from "react";
import "./landing.css";
import logo from "../../assets/logo.png";

interface LandingProps {}

interface LandingState {
  email: string;
  isInterestedInBetaTesting: boolean;
  name: string;
  subscriptionSuccess: boolean;
}

interface APIResponse {
  status: number;
}

class Landing extends Component<LandingProps, LandingState> {
  constructor(props: LandingProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      isInterestedInBetaTesting: false,
      subscriptionSuccess: false,
    };
  }

  render() {
    return (
      <div className="bg-img">
        <div className="bg-layout">
          {/* Title */}
          <header>
            <h2>MechTrade</h2>
          </header>

          {/* Center Section */}
          <div className="middle">
            <div className="content">
              <img src={logo} alt="MechTrade" />
              <div className="sub-titles">
                <span className="tag-line">
                  Buy, sell and trade mechanical keyboards and parts
                </span>
                <p>
                  Subscribe for updates, and optionally, join the beta testing
                  program!
                </p>
              </div>
              {/* Form */}
              <form className="flex-form" onSubmit={this.handleSubmit}>
                <input
                  placeholder="email address"
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  value={this.state.email}
                  onChange={this.onEmailChange}
                />
                <div className="beta">
                  <input
                    type="checkbox"
                    checked={this.state.isInterestedInBetaTesting}
                    onChange={this.onBetaTesterClick}
                  />
                  <label>Beta Tester?</label>
                </div>
                <button className="button" type="submit">
                  Subscribe
                </button>
              </form>
              <div className="btn-group">
                {/*Twitter*/}
                <a
                  href="https://twitter.com/mechtradeapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button-social"
                >
                  <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                    <path
                      fill="#FFF"
                      d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
                    />
                  </svg>
                </a>
                {/*Instagram*/}
                <a
                  href="https://instagram.com/mechtradeapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button-social"
                >
                  <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                    <path
                      fill="#FFF"
                      d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          {/* Made By */}
          <div className="made-by">
            <span>
              Photo by{" "}
              <a
                href="https://unsplash.com/@nihongraphy"
                target="_blank"
                rel="noopener noreferrer"
              >
                NihoNorwaygraphy
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();

    if (this.state.email === "") {
      alert("Please enter a valid email address");
      return;
    }

    try {
      const response = await axios({
        method: "POST",
        url:
          "https://4awc6oxhxf.execute-api.us-east-1.amazonaws.com/prod/newsletter/contact",
        data: this.state,
      });

      if (response.status && response.status >= 200 && response.status <= 299) {
        alert("Successfully added email");
        // this.onSubscriptionSuccess(true);
        this.resetForm();
      } else {
        alert("Please try again: Failed to save email");
      }
    } catch (error) {
      let alertMessage: string;
      if (error.response.data.error.description) {
        alertMessage = error.response.data.error.description;
      } else {
        alertMessage = "Failed to save email";
      }
      alert(`Please try again: ${alertMessage}`);
      // console.log(`Error: ${JSON.stringify(error.response)}`);
    }
  };

  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      isInterestedInBetaTesting: false,
      subscriptionSuccess: false,
    });
  };

  onNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  };

  onBetaTesterClick = (event: any) => {
    this.setState({ isInterestedInBetaTesting: event.target.checked });
  };

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  };

  onSubscriptionSuccess = (value: boolean) => {
    console.log("value");
    this.setState({ subscriptionSuccess: value });
  };
}

export default Landing;
